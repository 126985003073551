var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "用户昵称",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "nickname"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入用户昵称" },
                            model: {
                              value: _vm.model.nickname,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "nickname", $$v)
                              },
                              expression: "model.nickname"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "用户头像",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "avatar"
                          }
                        },
                        [
                          _c("j-image-upload", {
                            attrs: { isMultiple: "" },
                            model: {
                              value: _vm.model.avatar,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "avatar", $$v)
                              },
                              expression: "model.avatar"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "性别",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "gender"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "list",
                              dictCode: "sex",
                              placeholder: "请选择性别"
                            },
                            model: {
                              value: _vm.model.gender,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "gender", $$v)
                              },
                              expression: "model.gender"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "手机号码",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "mobile"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "请输入手机号码",
                              disabled: ""
                            },
                            model: {
                              value: _vm.model.mobile,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "mobile", $$v)
                              },
                              expression: "model.mobile"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "微信账号",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "wechatAccount"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              placeholder: "请输入微信账号",
                              disabled: ""
                            },
                            model: {
                              value: _vm.model.wechatAccount,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "wechatAccount", $$v)
                              },
                              expression: "model.wechatAccount"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "个人简介",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "introduction"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { rows: "4", placeholder: "请输入个人简介" },
                            model: {
                              value: _vm.model.introduction,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "introduction", $$v)
                              },
                              expression: "model.introduction"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "最后登录时间",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "lastVisitTime"
                          }
                        },
                        [
                          _c("j-date", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择最后登录时间",
                              "show-time": true,
                              "date-format": "YYYY-MM-DD HH:mm:ss"
                            },
                            model: {
                              value: _vm.model.lastVisitTime,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "lastVisitTime", $$v)
                              },
                              expression: "model.lastVisitTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }