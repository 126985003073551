var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticStyle: { top: "0px" },
      attrs: {
        title: "分屏",
        width: _vm.modalWidth,
        visible: _vm.visible,
        bodyStyle: _vm.bodyStyle,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "split-pane",
        {
          attrs: { "min-percent": 20, "default-percent": 50, split: "vertical" }
        },
        [
          _c("template", { slot: "paneL" }, [_c("split-panel-a")], 1),
          _c("template", { slot: "paneR" }, [_c("split-panel-b")], 1)
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }