var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1000,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.orderMainModel, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "orderCode",
                    label: "订单号",
                    required: "",
                    hasFeedback: ""
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入订单号" },
                    model: {
                      value: _vm.orderMainModel.orderCode,
                      callback: function($$v) {
                        _vm.$set(_vm.orderMainModel, "orderCode", $$v)
                      },
                      expression: "orderMainModel.orderCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单类型"
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请输入订单类型" },
                      model: {
                        value: _vm.orderMainModel.ctype,
                        callback: function($$v) {
                          _vm.$set(_vm.orderMainModel, "ctype", $$v)
                        },
                        expression: "orderMainModel.ctype"
                      }
                    },
                    [
                      _c("a-select-option", { attrs: { value: "1" } }, [
                        _vm._v("国内订单")
                      ]),
                      _c("a-select-option", { attrs: { value: "2" } }, [
                        _vm._v("国际订单")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单日期"
                  }
                },
                [
                  _c("a-date-picker", {
                    attrs: { showTime: "", valueFormat: "YYYY-MM-DD HH:mm:ss" },
                    model: {
                      value: _vm.orderMainModel.orderDate,
                      callback: function($$v) {
                        _vm.$set(_vm.orderMainModel, "orderDate", $$v)
                      },
                      expression: "orderMainModel.orderDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单金额"
                  }
                },
                [
                  _c("a-input-number", {
                    staticStyle: { width: "200px" },
                    model: {
                      value: _vm.orderMainModel.orderMoney,
                      callback: function($$v) {
                        _vm.$set(_vm.orderMainModel, "orderMoney", $$v)
                      },
                      expression: "orderMainModel.orderMoney"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "订单备注"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入订单备注" },
                    model: {
                      value: _vm.orderMainModel.content,
                      callback: function($$v) {
                        _vm.$set(_vm.orderMainModel, "content", $$v)
                      },
                      expression: "orderMainModel.content"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }