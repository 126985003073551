var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.confirmLoading } },
    [
      _c(
        "j-form-container",
        { attrs: { disabled: _vm.formDisabled } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: {
                slot: "detail",
                model: _vm.model,
                rules: _vm.validatorRules
              },
              slot: "detail"
            },
            [
              _c(
                "a-row",
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "院系",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              allowClear: "",
                              options: _vm.departmentList
                            },
                            model: {
                              value: _vm.model.departmentId,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "departmentId", $$v)
                              },
                              expression: "model.departmentId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "专业选择",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: { allowClear: "", options: _vm.majorList },
                            model: {
                              value: _vm.model.majorId,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "majorId", $$v)
                              },
                              expression: "model.majorId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "班级名称",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "name"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { placeholder: "请输入班级名称" },
                            model: {
                              value: _vm.model.name,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "备注",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "remark"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: { rows: "4", placeholder: "请输入备注" },
                            model: {
                              value: _vm.model.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "remark", $$v)
                              },
                              expression: "model.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "状态",
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            prop: "status"
                          }
                        },
                        [
                          _c("j-dict-select-tag", {
                            attrs: {
                              type: "radio",
                              dictCode: "status",
                              placeholder: "请选择状态"
                            },
                            model: {
                              value: _vm.model.status,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "status", $$v)
                              },
                              expression: "model.status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }