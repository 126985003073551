<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
           <a-col :span="24">
            <a-form-model-item label="班级" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="classId">
              <a-select allowClear  v-model="model.classId" :options="classData"></a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="班级动态标题" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="title">
              <a-input v-model="model.title" placeholder="请输入班级动态标题"  ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="班级内容" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="content">
              <j-editor v-model="model.content" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="置顶" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="isTop">
              <j-dict-select-tag type="radio" v-model="model.isTop" dictCode="is_top" placeholder="请选择置顶" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="发布状态" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="isPublish">
              <j-dict-select-tag type="list" v-model="model.isPublish" dictCode="send_status" placeholder="请选择发布状态" disabled/>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="isEnable">
              <j-dict-select-tag type="radio" v-model="model.isEnable" dictCode="status" placeholder="请选择状态" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
  </a-spin>
</template>

<script>

  import { httpAction, getAction } from '@/api/manage'
  import { validateDuplicateValue } from '@/utils/util'

  export default {
    name: 'ClassDynamicForm',
    components: {
    },
    props: {
      //表单禁用
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },
    data () {
      return {
        model:{
         },
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
        classData:[],
        confirmLoading: false,
        validatorRules: {
          classId: [
              { required: true, message: '请选择班级!'},
           ],
           title: [
              { required: true, message: '请输入班级动态标题!'},
           ],
           content: [
              { required: true, message: '请输入班级内容!'},
           ],
           isTop: [
              { required: true, message: '请输入置顶!'},
           ],
           isEnable: [
              { required: true, message: '请输入状态!'},
           ],
        },
        url: {
          add: "/ylpp/classDynamic/add",
          edit: "/ylpp/classDynamic/edit",
          queryById: "/ylpp/classDynamic/queryById",
          classPulldownUrl: 'ylpp/teacher/classes',
        }
      }
    },
    computed: {
      formDisabled(){
        return this.disabled
      },
    },
    created () {
       //备份model原始值
      this.modelDefault = JSON.parse(JSON.stringify(this.model));
      this.getClass();
    },
    methods: {
      getClass(name) {
       // var userId = store.getters.userInfo.id;
      httpAction(`${this.url.classPulldownUrl}`, {}, 'get')
        .then((res) => {
          if (res.success) {
            this.classData=res.result;
            this.classData.forEach((item) => {
              item.title = item.name
              item.value = item.id
            })
          } else {
          }
        })
        .finally(() => {
          this.confirmLoading = false;
        })
    },
      add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        this.model = Object.assign({}, record);
        this.visible = true;
      },
      submitForm () {
        const that = this;
        // 触发表单验证
        this.$refs.form.validate(valid => {
          if (valid) {
            that.confirmLoading = true;
            let httpurl = '';
            let method = '';
            if(!this.model.id){
              httpurl+=this.url.add;
              method = 'post';
            }else{
              httpurl+=this.url.edit;
               method = 'put';
            }
            httpAction(httpurl,this.model,method).then((res)=>{
              if(res.success){
                that.$message.success(res.message);
                that.$emit('ok');
              }else{
                that.$message.warning(res.message);
              }
            }).finally(() => {
              that.confirmLoading = false;
            })
          }
         
        })
      },
    }
  }
</script>