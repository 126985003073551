var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-layout",
    { attrs: { title: _vm.title } },
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "detail-list",
            { attrs: { title: "退款申请" } },
            [
              _c("detail-list-item", { attrs: { term: "取货单号" } }, [
                _vm._v("1000000000")
              ]),
              _c("detail-list-item", { attrs: { term: "状态" } }, [
                _vm._v("已取货")
              ]),
              _c("detail-list-item", { attrs: { term: "销售单号" } }, [
                _vm._v("1234123421")
              ]),
              _c("detail-list-item", { attrs: { term: "子订单" } }, [
                _vm._v("3214321432")
              ])
            ],
            1
          ),
          _c("a-divider", { staticStyle: { "margin-bottom": "32px" } }),
          _c(
            "detail-list",
            { attrs: { title: "用户信息" } },
            [
              _c("detail-list-item", { attrs: { term: "用户姓名" } }, [
                _vm._v("付小小")
              ]),
              _c("detail-list-item", { attrs: { term: "联系电话" } }, [
                _vm._v("18100000000")
              ]),
              _c("detail-list-item", { attrs: { term: "常用快递" } }, [
                _vm._v("菜鸟仓储")
              ]),
              _c("detail-list-item", { attrs: { term: "取货地址" } }, [
                _vm._v("浙江省杭州市西湖区万塘路18号")
              ]),
              _c("detail-list-item", { attrs: { term: "备注" } }, [
                _vm._v("\t无")
              ])
            ],
            1
          ),
          _c("a-divider", { staticStyle: { "margin-bottom": "32px" } }),
          _c("div", { staticClass: "title" }, [_vm._v("退货商品")]),
          _c("s-table", {
            staticStyle: { "margin-bottom": "24px" },
            attrs: { columns: _vm.goodsColumns, data: _vm.loadGoodsData }
          }),
          _c("div", { staticClass: "title" }, [_vm._v("退货进度")]),
          _c("s-table", {
            staticStyle: { "margin-bottom": "24px" },
            attrs: { columns: _vm.scheduleColumns, data: _vm.loadScheduleData },
            scopedSlots: _vm._u([
              {
                key: "status",
                fn: function(status) {
                  return [
                    _c("a-badge", {
                      attrs: {
                        status: status,
                        text: _vm._f("statusFilter")(status)
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }