<template>
  <a-spin :spinning="confirmLoading">
    <j-form-container :disabled="formDisabled">
      <a-form-model ref="form" :model="model" :rules="validatorRules" slot="detail">
        <a-row>
          <a-col :span="24">
            <a-form-model-item label="院系" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <!-- <j-dict-select-tag
                type="list"
                v-model="model.departmentId"
                :dictCode="`base_department where is_enable=1 and school_id_code=${schoolIdCode},name,id`"
                placeholder="请选择院系"
              /> -->
              <a-select allowClear v-model="model.departmentId" :options="departmentList"></a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="专业选择" :labelCol="labelCol" :wrapperCol="wrapperCol">
              <a-select allowClear v-model="model.majorId" :options="majorList"></a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="班级名称" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="name">
              <a-input v-model="model.name" placeholder="请输入班级名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="remark">
              <a-textarea v-model="model.remark" rows="4" placeholder="请输入备注" />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol" prop="status">
              <j-dict-select-tag type="radio" v-model="model.status" dictCode="status" placeholder="请选择状态" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </j-form-container>
  </a-spin>
</template>

<script>
import { httpAction, getLinkage,getAction} from '@/api/manage'
import { validateDuplicateValue } from '@/utils/util'

export default {
  name: 'TbClassForm',
  components: {},
  props: {
    //表单禁用
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },schoolIdCode: {
        type: String,
        default: "",
        required: true
      },
      departmentId: {
        type: String,
        default: "",
        required: true
      },
      majorId: {
        type: String,
        default: "",
        required: true
      },
  },
  data() {
    return {
      majorList: [],
      departmentList: [],
      model: {
        departmentId: '',
        majorId: '',
        status:1
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      validatorRules: {
        name: [
          { required: true, message: '请输入班级名称!' },
          {
            validator: (rule, value, callback) =>
              validateDuplicateValue('tb_class', 'name', value, this.model.id, callback),
          },
        ],
        status: [{ required: true, message: '请输入状态!' }],
        departmentId: [{ required: true,type:'number', message: '请输入院系!' }],
        majorId: [{ required: true,type:'number', message: '请输入专业!' }],
      },
      url: {
        add: '/ylpp/tbClass/add',
        edit: '/ylpp/tbClass/edit',
        queryById: '/ylpp/tbClass/queryById',
        baseDepartmentAll: 'ylpp/baseDepartment/list',
      },
    }
  },
  computed: {
    formDisabled() {
      return this.disabled
    },
  },
  watch: {
    //  如果需要第一次就执行监听 则需要设置：immediate: true
    'model.departmentId': {
      handler(newVal, oldVal) {
        if (newVal) {
          //this.model.majorId=null;
          this.renderMajor(newVal)
        }
        if(oldVal){
          this.model.majorId = ''
        }
      },
      immediate: false,
    },
  },
  created() {
    //备份model原始值
    this.modelDefault = JSON.parse(JSON.stringify(this.model))
  },
  methods: {
    baseDepartmentAll() {
      this.departmentList = []
      let params = {
        code: '',
        id: '',
        name: '',
        pageNo: '1',
        pageSize: '100',
        schoolIdCode: this.schoolIdCode,
        tenantId: '',
      }
      getAction(this.url.baseDepartmentAll, params).then((res) => {
        if (res.success) {
          this.departmentList=res.result.records;
          this.departmentList.forEach((item) => {
              item.title = item.name
              item.value = item.id
            })
        }
      })
    },
    add() {
      // this.edit(this.modelDefault)
     this.baseDepartmentAll();
     this.model = {}
     this.visible = true;
    this.model.status = 1;
    },
    edit(record) {
      this.baseDepartmentAll();
      this.renderMajor(record.departmentId)
      //console.log(record.departmentId)
      this.model = Object.assign({}, record)
      this.visible = true;
    },
    renderMajor(id) {
      let that = this
      getLinkage({ departmentId: id })
        .then((res) => {
          if (res.success) {
            that.majorList = res.result;
            that.majorList.forEach((item) => {
              item.title = item.name
              item.value = item.id
            })
          } else {
          }
        })
        .finally(() => {
          that.confirmLoading = false
        })
    },
    submitForm() {
      const that = this
      // 触发表单验证
      this.$refs.form.validate((valid) => {
        if (valid) {
          that.confirmLoading = true
          let httpurl = ''
          let method = ''
          if (!this.model.id) {
            httpurl += this.url.add
            method = 'post'
          } else {
            httpurl += this.url.edit
            method = 'put'
          }
          //this.model.departmentId=this.departmentId;
          this.model.schoolIdCode=this.schoolIdCode;
          //this.model.majorId=this.majorId;
          httpAction(httpurl, this.model, method)
            .then((res) => {
              if (res.success) {
                that.$message.success(res.message)
                that.$emit('ok')
              } else {
                that.$message.warning(res.message)
              }
            })
            .finally(() => {
              that.confirmLoading = false
            })
        }
      })
    },
  },
}
</script>